<template>
  <div class="menu">
    <div class="name">血糖监控系统</div>
    <div class="amenu">
      <a-menu
        class="menu-ba"
        mode="inline"
        theme="dark"
        :inlineCollapsed="collapsed"
        :selectedKeys="[$route.path]"
      >
        <template v-for="item in menuList">
          <template v-if="getJurisdictionList.indexOf(item.key) > -1">
            <a-sub-menu v-if="item.children && item.show!=false" :key="'/'+item.key">
              <span slot="title">
                <img :src="item.icon" alt />
                <span>{{ item.title }}</span>
              </span>
              <template v-for="items in item.children">
                <template v-if="getJurisdictionList.indexOf(items.key) > -1 && items.show != false">
                  <a-menu-item :key="'/'+items.key">
                    <router-link :to="items.path">{{items.title}}</router-link>
                  </a-menu-item>
                </template>
              </template>
            </a-sub-menu>
            <a-menu-item v-if="!item.children && item.show!=false" :key="'/'+item.key" @click="menu(item)">
              <router-link :to="item.path">
                <img :src="item.icon" alt />
                <span>{{ item.title }}</span>
              </router-link>
            </a-menu-item>
          </template>
        </template>
      </a-menu>
    </div>
    <!-- v-if="users.exclusiveCode" -->
    <div class="exclusivecode">医院专属码: {{ users.exclusiveCode }}</div>
  </div>
</template>

<script>
import storage from "../../storage/storage";
import menuList from "../../menuList";
export default {
  components: {},
  data() {
    return {
      getJurisdictionList: '',
      collapsed: false,
      users: "",
      menuList: menuList,
    };
  },
  mounted() {
    this.users = storage.getItem("users");
    // this.getMenu();
    this.getJurisdiction();
  },
  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem('getJurisdiction');
    },
    // 添加父级目录
    // getMenu() {
    //   this.menuList = [];
    //   let menuList = [];
    //   let getMenu = storage.getItem("getJurisdiction");
    //   getMenu.forEach((item) => {
    //     menuList.push(item.split("-")[0]);
    //   });
    //   for (const i in this.jurisdictionList) {
    //     for (const j in [...new Set(menuList)]) {
    //       if (this.jurisdictionList[i].key == [...new Set(menuList)][j] + "") {
    //         this.menuList.push({
    //           key: "" + this.jurisdictionList[i].key,
    //           title: this.jurisdictionList[i].title,
    //           path: "/" + this.jurisdictionList[i].key,
    //         });
    //       }
    //     }
    //   }
    // },
    menu() { },
  },
};
</script>
<style scoped lang="less">
.menu {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  background: #29357b;
  border-radius: 30px 0 0 30px;
}
.name {
  font-size: 22px;
  color: #fff;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-bottom: 1px solid #e7e7e7;
}
/deep/ .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #29357b !important;
  box-shadow: unset;
}

/deep/ .ant-menu-item,
/deep/ .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 35px !important;
  img {
    width: 20px;
    margin-right: 8px;
  }
  span {
    color: #fff;
  }
}
/deep/ .ant-menu-sub .ant-menu-item {
  padding-left: 68px !important;
}
.menu-ba {
  background: #29357b;
  width: 100%;
}
.amenu .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background: #4d5ec2;
  color: #fff;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: #fff;
}
.exclusivecode {
  position: absolute;
  bottom: 50px;
  width: 100%;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}
</style>
